<template>
  <div class="2c2p-success">
    <app-2c2p-success-page />
  </div>
</template>

<script>
import TwoCTwoPSuccessPageComponent from "@/components/2c2pSuccessPage/2c2pSuccessPageComponent.vue";

export default {
  name: "2c2pSuccessPageView",
  components: {
    "app-2c2p-success-page": TwoCTwoPSuccessPageComponent,
  },
};
</script>
