<template>
  <div>
    <div v-if="loading">
      <LoadingScreen />
    </div>

    <div v-else-if="isStatus">
      <SuccessScreen>
        <p class="ref has-text-centered">
          Ref No. {{ accountReferenceNumber }}
        </p>
        <p class="date has-text-centered">{{ accountDate }}</p>
      </SuccessScreen>
    </div>

    <div v-else>
      <ErrorScreen />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "@/Common/LoadingScreen.vue";
import SuccessScreen from "@/Common/SuccessScreen.vue";
import ErrorScreen from "@/Common/ErrorScreen.vue";

export default {
  name: "AltpaySuccessPageComponent",
  components: {
    SuccessScreen,
    ErrorScreen,
    LoadingScreen,
  },
  data() {
    return {
      referenceNumber: "",
      date: "",
      status: null,
      loading: true,
    };
  },
  filters: {
    Currency: function (value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "Php",
      });

      return formatter.format(value);
    },
  },
  computed: {
    accountReferenceNumber() {
      return this.referenceNumber;
    },
    accountDate() {
      return moment(this.date).format("MMM. D, YYYY h:mm A z");
    },
    isStatus() {
      return this.status;
    },
  },
  async mounted() {
    await this.getAltPayDetails();
    this.loading = false;
  },
  methods: {
    async getAltPayDetails() {
      if (this.$route.query.referenceNumber !== undefined) {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}transactions/funds/cash-in/details/altpay`,
          {
            transactionReferenceNumber: this.$route.query.referenceNumber,
            transactionID: this.$route.query.transaction_id,
          }
        );

        const details = response.data.data;

        this.referenceNumber = details.transactionReferenceNumber;
        this.date = details.transactionDateTime;

        this.status =
          details.altpayDetails?.result?.status_code?.includes("OK");
      }
    },
  },
};
</script>

<style scoped>
.ref,
.date {
  font-size: 14px;
  color: #595959;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 425px) {
  .ref,
  .date {
    font-size: 14px;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 375px) {
  .ref,
  .date {
    font-size: 13px;
    word-wrap: break-word;
  }
}
</style>
