<template>
  <div class="altpay">
    <app-altpay-page />
  </div>
</template>

<script>
import AltPayPageComponent from "@/components/AltPayPage/AltPayPageComponent.vue";

export default {
  name: "AltPayPageView",
  components: {
    "app-altpay-page": AltPayPageComponent,
  },
};
</script>
