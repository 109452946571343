<template>
  <div class="ipay88-success">
    <app-ipay88-success-page />
  </div>
</template>

<script>
import Ipay88SuccessPageComponent from "@/components/Ipay88SuccessPage/Ipay88SuccessPageComponent.vue";

export default {
  name: "Ipay88SuccessPageView",
  components: {
    "app-ipay88-success-page": Ipay88SuccessPageComponent,
  },
};
</script>
