<template>
  <ErrorScreen />
</template>

<script>
import axios from "axios";
import ErrorScreen from "@/Common/ErrorScreen";

export default {
  name: "twoCtwoPErrorPageComponent",
  components: { ErrorScreen },
  computed: {
    accountReferenceNumber() {
      return this.referenceNumber;
    },
  },
  async mounted() {
    await this.getCashInDetails();
  },
  methods: {
    async getCashInDetails() {
      if (this.$route.query.txnid !== undefined) {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}transactions/funds/cash-in/details/2c2p?txnid=${this.$route.query.txnid}`,
          {
            referenceNumber: this.$route.query.txnid,
          }
        );
      }
    },
  },
};
</script>
