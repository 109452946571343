<template>
  <SuccessScreen>
    <p class="ref has-text-centered">Ref No. {{ accountReferenceNumber }}</p>
    <p class="date has-text-centered">{{ accountDate }}</p>
  </SuccessScreen>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SuccessScreen from "@/Common/SuccessScreen.vue";

export default {
  name: "Ipay88SuccessPageComponent",
  components: { SuccessScreen },
  data() {
    return {
      referenceNumber: "",
      date: "",
    };
  },
  filters: {
    Currency: function (value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "Php",
      });

      return formatter.format(value);
    },
  },
  computed: {
    accountReferenceNumber() {
      return this.referenceNumber;
    },
    accountDate() {
      return moment(this.date).format("MMM. D, YYYY h:mm A z");
    },
  },
  async mounted() {
    await this.getCashInDetails();
  },
  methods: {
    async getCashInDetails() {
      if (this.$route.query.txnid !== undefined) {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}transactions/funds/cash-in/details/ipay88?txnid=${this.$route.query.txnid}`,
          {
            referenceNumber: this.$route.query.txnid,
          }
        );

        this.referenceNumber = response.data.data.referenceNumber;
        this.date = response.data.data.transactionDateTime;
      }
    },
  },
};
</script>

<style scoped>
.ref,
.date {
  font-size: 14px;
  color: #595959;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 425px) {
  .ref,
  .date {
    font-size: 14px;
    word-wrap: break-word;
  }
}

@media screen and (max-width: 375px) {
  .ref,
  .date {
    font-size: 13px;
    word-wrap: break-word;
  }
}
</style>
