<template>
  <div class="ipay88-error">
    <app-ipay88-error-page />
  </div>
</template>

<script>
import Ipay88ErrorPageComponent from "@/components/Ipay88ErrorPage/Ipay88ErrorPageComponent.vue";

export default {
  name: "Ipay88ErrorPageView",
  components: {
    "app-ipay88-error-page": Ipay88ErrorPageComponent,
  },
};
</script>
