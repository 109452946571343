<template>
  <div class="maya-error">
    <app-maya-error-page />
  </div>
</template>

<script>
import MayaErrorPageComponent from "@/components/MayaErrorPage/MayaErrorPageComponent.vue";

export default {
  name: "MayaErrorPageView",
  components: {
    "app-maya-error-page": MayaErrorPageComponent,
  },
};
</script>
