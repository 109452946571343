<template>
  <div class="2c2p-error">
    <app-2c2p-error-page />
  </div>
</template>

<script>
import TwoCTwoPErrorPageComponent from "@/components/2c2pErrorPage/2c2pErrorPageComponent.vue";

export default {
  name: "2c2pErrorPageView",
  components: {
    "app-2c2p-error-page": TwoCTwoPErrorPageComponent,
  },
};
</script>
