<template>
  <div class="maya-success">
    <app-maya-success-page />
  </div>
</template>

<script>
import MayaSuccessPageComponent from "@/components/MayaSuccessPage/MayaSuccessPageComponent.vue";

export default {
  name: "MayaSuccessPageView",
  components: {
    "app-maya-success-page": MayaSuccessPageComponent,
  },
};
</script>
