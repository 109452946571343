<template>
  <ErrorScreen />
</template>

<script>
import axios from "axios";
import ErrorScreen from "@/Common/ErrorScreen";

export default {
  name: "Ipay88ErrorPageComponent",
  components: { ErrorScreen },
  data() {
    return {};
  },
  filters: {},
  computed: {
    accountReferenceNumber() {
      return this.referenceNumber;
    },
  },
  async mounted() {
    await this.getCashInDetails();
  },
  methods: {
    async getCashInDetails() {
      if (this.$route.query.txnid !== undefined) {
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}transactions/funds/cash-in/details/ipay88?txnid=${this.$route.query.txnid}`,
          {
            referenceNumber: this.$route.query.txnid,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
#ipay88-error-page {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 87, 228, 1) 40%,
    rgba(255, 255, 255, 1) 40%
  );
  height: 100vh;
  width: 100vw;
}

.ipay88-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100%;
  width: 100%;
}

.ipay88-error-content {
  margin: 1rem;
  padding: 3rem 1rem;
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
}

.ipay88-error-header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 1rem auto;
}

.ipay88-error-description {
  margin: 0 auto;
  width: 65%;
}

@media screen and (max-width: 425px) {
  .ipay88-error-logo-mobile {
    width: 250px;
  }

  .ipay88-error-cat-logo-mobile {
    width: 150px;
  }

  .ipay88-error-description {
    width: 80%;
  }
}
</style>
